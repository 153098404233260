import React from 'react'
import Layout from '../components/layout'
import { Container } from 'theme-ui'

const Resources = () => {
  return (
    <Layout variant='sidebar'>
      <Container variant='layout'>
        <h1>Resources</h1>
      </Container>
    </Layout>
  )
}
export default Resources
